export const WORDS = [
  'adele',
  'aimee',
  'allen',
  'arias',
  'ariel',
  'award',
  'bauer',
  'break',
  'brent',
  'bruce',
  'burli',
  'chair',
  'chris',
  'clara',
  'clyde',
  'dance',
  'danny',
  'eddie',
  'entry',
  'fader',
  'forth',
  'happy',
  'heart',
  'humes',
  'intro',
  'james',
  'julie',
  'magic',
  'media',
  'metro',
  'mills',
  'music',
  'north',
  'ofcom',
  'onair',
  'payne',
  'pizza',
  'promo',
  'pulse',
  'radio',
  'roman',
  'ronan',
  'scala',
  'segue',
  'sound',
  'spark',
  'sting',
  'tabor',
  'tests',
  'times',
  'tobin',
  'trent',
  'voice',
  'zetta',
  'welby',
]
